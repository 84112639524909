import "./index.css";

import React from "react";

import { Provider } from "react-redux";
import store from "./stores/redux-store";

import { HashRouter } from "react-router-dom";
import Routes from "./Routes";
import { Link } from 'react-router-dom';
import {Fa, Footer} from "mdbreact";


class App extends React.Component {

  constructor(props) {
      super(props);
      // STEP 1: create a container <div>
      this.containerEl = document.createElement('div');
      this.externalWindow = null;
  }

  openPrivacyPolicy() {
    //alert('test');
    this.externalWindow = window.open('https://policies.helloworldlimited.com.au/privacy-policy/');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  openCookiePolicy() {
    //alert('test');
    this.externalWindow = window.open('https://policies.helloworldlimited.com.au/cookies-policy/');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  openTermsOfUse() {
    //alert('test');
    this.externalWindow = window.open('https://www.cruising.com.au/terms-and-conditions');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  render() {

    return (

      <Provider store={store}>
        <HashRouter>
          <div className="flyout">

            <main>
              <Routes />
            </main>

            <div className="fixed-footer">
              {/*<div className="label-footer1">
              <p>Information on this Web Site has been obtained from the Cruise Companies represented. However, the cruise industry is constantly changing and should be used as a guide only. We are unable to take responsibility for incorrect information and you must confirm all details at the time of booking</p>
              </div>*/}

              <div className="col-md-12 label-footer2">
                <div className="form-row">
                  <div className="col-md-6">
                  <a style={{color:"#fff"}} href="tel:1300137330"><Fa icon="phone" className="mr-2"/>1300 137 330</a>
                  </div>
                  <div className="col-md-6">
                  <a style={{color:"#fff"}} href="mailto:neptune@sevenoceans.com.au"><Fa icon="envelope" className="mr-2"/>neptune@sevenoceans.com.au</a>
                  </div>
                </div>
              </div>
              <div className="col-md-12 label-footer3">
                <p className="mb-0 py-1 text-center">
                  © Copyright 2019 Seven Oceans Cruising All rights reserved. <a href="" style={{color:"red"}} onClick={this.openPrivacyPolicy} >Privacy Policy</a> | <a href="" style={{color:"red"}} onClick={this.openCookiePolicy} >Cookie Policy</a> | <a href="" style={{color:"red"}} onClick={this.openTermsOfUse} >Agency Terms of Use</a>
                </p>
                <p className="text-center">
                  <font size="2">Viva Holidays Limited(ABN 24 003 836 459) trading as Seven Oceans Cruising</font>
                </p>
              </div>
            </div>

          </div>

        </HashRouter>
      </Provider>

    );
  }
}

export default App;
