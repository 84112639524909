import React from 'react';

import { Container } from "mdbreact";
import './terms-cond-component.css';
import HeaderContainer from '../header/header-container';

class BkgTermsConditionsComponent extends React.Component {

  render() {
    return (
      <div className="home-component">
        <HeaderContainer {...this.props} />
        <div>
          <div>
            <Container  className="mt-4 search-filter">

            <div className="search-label">
              <p><font size="6"><b>Booking Terms and Conditions</b></font></p>
              <hr/>
               <font size="3">
               <p><b>ONLINE BOOKINGS</b></p>

                <p>We hold the right to reject online bookings if we believe that they are not genuine or are fraudulent. Some online fares will incur a booking fee or service charge. Airfares are not guaranteed until ticketed as the airline may change the fare without notice before the ticketing is done.</p>

                <p><b>APPROXIMATE BAGGAGE ALLOWANCE</b></p>

                <p>Cabin and checked baggage allowance does vary from airline to airline, but as a general guide:</p>

                <p>First Class:         40 kg free baggage allowance per person.</p>

                <p>Business Class:  30 kg free baggage allowance per person.</p>

                <p>Economy Class:  20 kg free baggage allowance per person.</p>

                <p>For travel to and from the USA, the free baggage allowance is 1 piece per person. Please check with your consultant for dimension and weight information. Please note that some domestic airfares and Trans-Tasman airfares may not include any checked baggage. Visit https://www.skiddoo.com.au/help to view further baggage allowance details.</p>

                <p><b>RECONFIRMING YOUR FLIGHTS</b></p>

                <p>We recommend that you check your flight details with the local airline office within 72 hours of departure, in case the airline has made any late changes and Skiddoo has not been informed or has not been able to contact you to advise these changes. If you fail to do this, and there has been some changes, then your travel plans may be disrupted and any additional cost would need to be borne by yourself.</p>

                <p><b>CREDIT CARD TRANSACTIONS</b></p>

                <p>The credit card holder must be travelling. We do not accept any third party credit cards and we hold the right to reject your credit card. In the event that payment has been made to Skiddoo Pty Ltd by credit card, you agree that you will not seek to charge back your payment to Skiddoo. Where you pay by cheque, you agree not to stop payment of the cheque even when you cancel a booking. You agree that we may apply the proceeds of the cheque to satisfy any liability you have to us, including cancellation fees, before refunding the balance to you.</p>

                <p><b>FREQUENT FLYERS</b></p>

                <p>Please advise your travel consultant your frequent flyer details. Your consultant will pass these details on to the relevant supplier, but we cannot guarantee that the supplier will credit any points for your booking. Airlines have different policies on frequent flyer points accrual, so therefore Skiddoo does not guarantee that all fares displayed will accrue points. It is your responsibility to check if the fare you are planning to purchase does accrue such points. Please retain all boarding passes to allow verification of your travel if required.</p>

                <p><b>CANCELLATION AND REFUND FEES</b></p>

                <p>Cancelled bookings will incur charges, which can be up to 100% of the cost of booking, regardless of whether travel has commenced. Check with your consultant and the fare rules if the fare is refundable, as not all fares can be refunded. Our administration fee is $133.00 per ticket plus any airline cancellation fees. Refunds take approximately 12 weeks to process. If we incur any liability for a cancellation fee for any booking cancelled by you, you agree to indemnify us for the amount of that fee or charge.</p>

                <p><b>CHANGE & REISSUE FEES</b></p>

                <p>Fees will also apply where a booking is changed or tickets are reissued. Please check with your consultant whether the fare is changeable. Fees will apply to all changeable fares. Our administration fee is $72.00 per ticket, per change plus any airline charges. Where we incur any liability for a charge or reissue for any booking, you agree to indemnify us for the amount of that fee or charge.</p>

                <p><b>BOOKING FEES</b></p>

                <p>Skiddoo charges no booking fees on most international flights except on low cost carriers - Scoot, Jetstar,Cebu Pacific,Tiger and Skipper - when a booking fee of $29.95 per person will be charged.<br/>
                There is no fee on Australian MasterCard credit cards.<br/>
                A fee applies to the following cards:</p>

                <p>Australian Visa & MasterCard  debit cards - 0.6%<br/>
                Australian Visa credit cards - 1.1%<br/>
                American Express - 1.9%<br/>
                Diners and Discover credit cards - 1.5%<br/>
                Non Australian Visa & MasterCard - 2.0%</p>
                <p>A surcharge of 1.0% applies to PayPal payments and 1.9% applies to Visa Checkout payments.</p>

                <p><b>TRAVEL DOCUMENTS</b></p>

                <p>Travel documents include airline tickets, hotel vouchers, tour vouchers or any other document (whether in electronic form or otherwise) used to confirm an arrangement with a service provider. Travel documents may be subject to certain conditions and/or restrictions including being non-refundable, non-date-changeable, non-transferable and subject to cancellation and/or amendment fees. All airline tickets must be issued in the name of the passport/photo identity holder. Any errors in names on your documentation will be your responsibility if not advised at the time of booking. It is your responsibility to collect all travel documents from us prior to travel.</p>

                <p><b>PRICE</b></p>

                <p>All prices are subject to availability and can be withdrawn or varied without notice. The price is only guaranteed when paid in full and tickets issued, prices quoted are subject to change. Such factors include adverse currency fluctuations, fuel prices, taxes and airfare increases. Please contact us for up-to-date prices.</p>

                <p><b>TRAVEL INSURANCE</b></p>

                <p>We are an authorised representative of Chubb Insurance Australia Limited. Financial product advice in this document has been prepared without taking into account your current situation. You should consider the appropriateness of it having in regard to your objectives, financial situation or needs. Travel Insurance is strongly recommended by the Department of Foreign Affairs and Trade for all overseas travel.</p>

                <p><b>CHECK IN</b></p>

                <p>We recommend that you check in at least 3 hours before your flight's scheduled departure time for international flights, and at least 60 mins before your flights scheduled departure time for domestic flights. Failure to do so may result in you being denied boarding, any associated costs involved would be borne by yourself.</p>

                <p><b>PASSPORTS AND VISAS</b></p>

                <p>It is your responsibility to ensure that you have valid passports, visas and re-entry permits which meet the requirements of immigration and other services and other government authorities. Any fines, penalty payments or expenditure incurred as a result of such documents not meeting the requirements of those authorities will be your sole responsibility. All travellers must have a valid passport for international travel and many countries require at least 6 months validity from the date of entry.</p>

                <p><b>TAXES</b></p>

                <p>Certain taxes are mandatory in various countries. There may also be an additional local tax charged at some airports. All taxes are subject to change without notice.</p>

                <p><b>AGENCY</b></p>

                <p>Skiddoo acts as a travel agent only. We sell various travel-related products on behalf of numerous transport, accommodation and other wholesale service providers. All bookings are made subject to the terms and conditions and limitation of liability imposed by these service providers. Your legal recourse is against the specific provider, not Skiddoo. If, for any reason, any travel service provider is unable to provide the services for which you have contracted your remedy lies against the provider not Skiddoo.</p>

                <p><b>AIRLINE GENERATED FLIGHT AND/OR TIME CHANGES (SCHEDULE CHANGES)</b></p>

                <p>The airline undertakes to use its best efforts to carry the passenger and baggage with reasonable dispatch. Times shown in timetables or elsewhere (including on the ticket) are not guaranteed and form no part of the contract which is your ticket. Carrier may without notice substitute alternate carriers or aircraft, and may alter or omit stopping places shown on the ticket, and may change flight times or cancel flights in case of necessity. In these events, a reasonable alternative will be offered. If no reasonable alternative is possible, then the airline will offer a full refund.Schedules are subject to change without notice. The airline, and Skiddoo, assumes no responsibility for making connections.</p>

                <p><b>LIABILITY</b></p>

                <p>We do not accept any liability of whatever nature for the acts, omissions or default, whether negligent or otherwise, of third party providers over whom we have no direct control. Under circumstances where liability cannot be excluded, such liability is limited to the value of the purchased travel arrangements. We do not accept any liability for any injury, damage, loss, delay, additional expense or inconvenience caused directly or indirectly, by force majeure or any other event which is beyond our control.</p>

                <p><b>SPECIAL REQUIREMENTS</b></p>

                <p>Please advise our travel consultant of any special requirements you may have, such as special meals, medical requirements, et cetera.</p>

                <p><b>TRAVEL ADVICE</b></p>

                <p>For travel advice, please contact the Department of Foreign Affairs and Trade or visit their website at www.smartraveller.gov.au.</p>

                <p><b>HEALTH</b></p>

                <p>It is your responsibility to ensure that you are aware of any health requirements for your travel destinations and to ensure that you carry all necessary vaccination documentation.</p>

                <p><b>STUDENT FLIGHTS</b></p>
                <ul>
                	<li>Student fares are limited to those only aged 18 - 30 when travelling alone.</li>
                	<li>Students aged 12 - 17 may purchase student fares as long as they travel with someone aged at least 18 years.</li>
                	<li>Students must ensure they carry their student ID always otherwise risk the possibility of being denied boarding at the airport.</li>
                	<li>Skiddoo reserves the right to withhold ticket issuance without relevant accreditation and proof of student eligibility.</li>
                </ul>

                <p><b>YOUTH FLIGHTS</b></p>
                <ul>
                	<li>Youth fares are limited to those only aged 18 - 30 when travelling alone.</li>
                	<li>Youths aged 12 - 17 may purchase youth fares as long as they travel with someone aged at least 18 years.</li>
                	<li>Date of birth and passport details (where applicable) will be checked prior to e-ticket issuance. Failure to provide relevant documentation will result in booking cancellation.</li>
                </ul>

                <p><b>FLEXIBLE TICKET</b></p>

                <p>The traveller must have subscribed to and paid for this customer service at the same time as booking the trip.<br/>
                The service cannot be added after the booking is made.<br/>
                <strong>Flexible Ticket</strong> may not be available on all fares and on all routes. If available then it will be offered during the online booking process. If not available, then it will not be offered.<br/>
                The change using <strong>Flexible Ticket</strong> can not be made within 48 business hours of either the existing travel date, or the new travel date being changed to.<br/>
                If the change required results in a higher Airfare, then the fare difference must still be paid. <strong>Flexible Ticket</strong> does not cover the fare difference.<br/>
                Airline change rules will always state that any change must be to an equal or higher fare. Downtrading to a lower fare is never permitted, even if you are not expecting any refund.<br/>
                <strong>Flexible Ticket</strong> is Skiddoo&#39;s own service. If the Airline fare rules state that no changes are permitted, then the Airline rules will prevail.<br/>
                All rebooking through <strong>Flexible Ticket</strong> must be done via Skiddoo&rsquo;s customer service centre.<br/>
                <strong>Flexible Ticket</strong> is valid only for the person who has purchased it,and is non-transferable.<br/>
                <strong>Flexible Ticket</strong> does not allow for a change of Airline (unless the Airline&rsquo;s rebooking conditions permit this).<br/>
                <strong>Flexible Ticket</strong> does not cover:
                </p>

                <p>a. Re-routing. The original origin and departure cities must be maintained.<br/>
                b. Name change or corrections.<br/>
                c. Upgrading to a higher cabin of travel (eg Economy to Business Class).<br/>
                d. Adding a stopover, where no stopover existed on the original booking.</p>

                <p>Normal Airline reservation and ticketing rules apply. Flights must be used in sequence according to the itinerary.<br/>
                <strong>Flexible Ticket</strong> may be utilised once only. Once used, the service expires.<br/>
                <strong>Flexible Ticket</strong> fee is non refundable in the event of a voluntary cancellation.<br/>
                If you are extending the duration of your stay, you are responsible for your own Visas (if required).<br/>
                If you have purchased any supplementary services from the Airline, such as special seating or additional baggage, then the cost of this may be forfeited in making the change. Skiddoo will not be responsible for any re-imbursement.<br/>
                Any request for change under <strong>Flexible Ticket</strong> must be first assessed by Skiddoo to ensure that all Airline change and re-issue conditions have been met.</p>


                <p><b>BOOK NOW, PAY LATER</b></p>

                <ul>
                	<li>If you select the <strong>Book Now, Pay Later</strong> option, Skiddoo will hold and guarantee the booking and price upon you paying the deposit shown. Except:
                  	<ul class="a">
                  		<li>In some cases, the airline may withdraw or increase the fare, and/or cancel the unticketed booking, without notice.</li>
                  		<li>In such cases, Skiddoo will do all possible to assist you to find a suitable alternative, however we can not guarantee and may not be able to provide the original itinerary/fare.</li>
                  		<li>If this were to happen, then Skiddoo will refund your deposit in full, and not be responsible for any additional amount that you may need to pay for a higher fare.</li>
                  	</ul>
                	</li>
                	<li>This deposit will be used towards the full payment of the booking you are holding.</li>
                	<li>The deposit may not be transferred to a different booking, or to another person.</li>
                	<li>All deposits are non-refundable in the event of a voluntary change on your part regardless of the reason, or if you choose not to go ahead with the booking (subject to your rights under Australian Consumer Law).</li>
                	<li>You will need to contact Skiddoo within 48 hours, to make your final payment. Failure to do so will result in your booking being cancelled and deposit being forfeited.</li>
                	<li><strong>Book Now, Pay Later</strong> may not be offered for all airlines and all itineraries.</li>
                	<li><strong>Book Now, Pay Later</strong> is a Skiddoo initiative, so the final payment must be made to Skiddoo and may not be made to the airline, or through any other source.</li>
                	<li>Book Now, Pay Later may not be combined with or used in conjunction with any discount/promo code.</li>
                </ul>

               </font>
            </div>

            </Container>
          </div>

        </div>

      </div>
    );
  }
}

export default BkgTermsConditionsComponent;
