export const CONSTANT = {
	LDAP_OU_USERS: "users",
	LDAP_OU_GROUPS: "groups",
	LDAP_OU_PROJECTS: "projects",
	LDAP_OU_PWD_POLICIES: "pwdPolicies",
	LDAP_ATTR_CN: "cn",
	LDAP_ATTR_MAIL: "mail",
	LDAP_ATTR_OBJECTCLASS: "objectClass",
	LDAP_ATTR_PWDATTRIBUTE: "pwdAttribute",
};

export const SESSION = {
	GENERAL: {
		DEBUG: "ACTION_GENERAL_DEBUG",
		ENABLEDEBUG: "ACTION_GENERAL_ENABLEDEBUG",
		STORE_USER_PAGE: "ACTION_STORE_USER_PAGE"
	},
	USER: {
		LOGIN: "ACTION_USER_LOGIN",
		LOGOUT: "ACTION_USER_LOGOUT",
		DETAILS: "ACTION_USER_DETAILS",
	},
	ACTIONS: {
		RESET_LOGIN_ERRMSG: "ACTION_RESET_LOGIN_ERRMSG"
	},
	REQUESTS: {
		LOGIN_FETCH: "REQUEST_LOGIN_FETCH",
		LOGIN_SUCCESS: "REQUEST_LOGIN_SUCCESS",
		LOGIN_FAILURE: "REQUEST_LOGIN_FAILURE",
		SSO_FETCH: "REQUEST_SSO_FETCH",
		SSO_SUCCESS: "REQUEST_SSO_SUCCESS",
		SSO_FAILURE: "REQUEST_SSO_FAILURE",
		TOKEN_FETCH: "REQUEST_TOKEN_FETCH",
		TOKEN_SUCCESS: "REQUEST_TOKEN_SUCCESS",
		TOKEN_FAILURE: "REQUEST_TOKEN_FAILURE",
		CHANGE_PASSWORD_SUCCESS: "REQUEST_CHANGE_PASSWORD_SUCCESS",
		CHANGE_PASSWORD_FAILURE: "REQUEST_CHANGE_PASSWORD_FAILURE",
		SET_TOURPLAN_CODE: "REQUEST_SET_TOURPLAN_CODE",
	}
};

export const LDAP = {
	REQUESTS: {
		GET_LDAP_HIERARCHY_FETCH: "REQUEST_GET_LDAP_HIERARCHY_FETCH",
		GET_LDAP_HIERARCHY_SUCCESS: "REQUEST_GET_LDAP_HIERARCHY_SUCCESS",
		GET_LDAP_HIERARCHY_FAILURE: "REQUEST_GET_LDAP_HIERARCHY_FAILURE",
		GET_LDAP_SITES_FETCH: "REQUEST_GET_LDAP_SITES_FETCH",
		GET_LDAP_SITES_SUCCESS: "REQUEST_GET_LDAP_SITES_SUCCESS",
		GET_LDAP_SITES_FAILURE: "REQUEST_GET_LDAP_SITES_FAILURE",
		GET_LDAP_USERS_FETCH: "REQUEST_GET_LDAP_USERS_FETCH",
		GET_LDAP_USERS_SUCCESS: "REQUEST_GET_LDAP_USERS_SUCCESS",
		GET_LDAP_USERS_FAILURE: "REQUEST_GET_LDAP_USERS_FAILURE",
		GET_LDAP_GROUPS_FETCH: "REQUEST_GET_LDAP_GROUPS_FETCH",
		GET_LDAP_GROUPS_SUCCESS: "REQUEST_GET_LDAP_GROUPS_SUCCESS",
		GET_LDAP_GROUPS_FAILURE: "REQUEST_GET_LDAP_GROUPS_FAILURE",
		GET_SITE_USERS_FETCH: "REQUEST_GET_SITE_USERS_FETCH",
		GET_SITE_USERS_SUCCESS: "REQUEST_GET_SITE_USERS_SUCCESS",
		GET_USER_ATTR_FETCH: "REQUEST_GET_USER_ATTR_FETCH",
		GET_USER_ATTR_SUCCESS: "REQUEST_GET_USER_ATTR_SUCCESS",
		GET_OU_ATTR_FETCH: "REQUEST_GET_OU_ATTR_FETCH",
		GET_OU_ATTR_SUCCESS: "REQUEST_GET_OU_ATTR_SUCCESS",
	},
	USER: {
		LOGOUT: "ACTION_LDAP_USER_LOGOUT",
	},
	ACTIONS: {
		SET_PAGE_CONTROLS: "ACTION_SET_PAGE_CONTROLS"
	},
};

export const VERA = {
	REQUESTS: {
		GET_SEARCH_OPT_FETCH: "REQUEST_GET_SEARCH_OPT_FETCH",
		GET_SEARCH_OPT_SUCCESS: "REQUEST_GET_SEARCH_OPT_SUCCESS",
		GET_SEARCH_OPT_FAILURE: "REQUEST_GET_SEARCH_OPT_FAILURE",
	}
};
