import React from 'react';

import { Container } from "mdbreact";
import './terms-cond-component.css';
import HeaderContainer from '../header/header-container';

class TermsConditionsComponent extends React.Component {

  render() {
    return (
      <div className="home-component">
        <div>
          <div>
            <Container  className="mt-4 search-filter">

            <div className="search-label">
                <p>OUR BOOKING PLATFORM HAS MOVED</p>
                <p>Click <a href="https://pro.cruising.com.au/admin/login.aspx"><u>HERE</u></a> to be redirected to our new CruisePRO platform</p>
                <p>If you have trouble logging in, please click <a href=""  onClick={this.openNewWindow} ><u>here</u></a> for more assistance.</p>
                <p>Click <u>HERE</u> for more information on using CruisePRO</p>
            </div>

            <br/>
            <br/>
            <br/>
            </Container>
          </div>

        </div>

      </div>
    );
  }
}

export default TermsConditionsComponent;
