import { connect } from 'react-redux';

import BkgTermsConditionsComponent from './bkg-terms-cond-component';

const mapStateToProps = (state) => {
	return {
		user: state.sessionStore.user
	}
};

const mapDispatchToProps = (dispatch) => {
	return {

	}
}

const BkgTermsConditionsContainer = connect( mapStateToProps, mapDispatchToProps )(BkgTermsConditionsComponent)



export default BkgTermsConditionsContainer
