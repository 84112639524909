import request from 'superagent'


// --------------- Search Options ---------------
export function requestSendEmail(payload) {
	return function (dispatch) {
		//alert('requestSendEmail >> request: '+JSON.stringify(payload))
		const URL = 'https://vera.secure-travel.net/mailman/api/email/send';
		//const URL = 'https://vera.secure-travel.net/vera/api/search/sendEmail';
		//alert('URL: '+URL)
		request.post(URL)
			.set('Accept', 'application/json')
			.send(payload)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => console.log(response.body)
			).catch(
				error => console.log('XXX: '+error)
			);
	}
};
