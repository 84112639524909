
import { connect } from 'react-redux';

import { actionUserLogout, actionLDAPUserLogout } from '../../actions/session-actions'

import HeaderComponent from './header-component';


const mapStateToProps = (state) => {
	return {
		user: state.sessionStore.user
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onLogoutClick: (payload) => {
			dispatch(actionUserLogout());
			dispatch(actionLDAPUserLogout());
		},
		/*onDebugClick: () => {
			dispatch(actionDebugMode());
		},*/
	}
}

const HeaderContainer = connect( mapStateToProps, mapDispatchToProps )(HeaderComponent)



export default HeaderContainer
