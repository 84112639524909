import { connect } from 'react-redux';

import TermsConditionsComponent from './terms-cond-component';

const mapStateToProps = (state) => {
	return {
		user: state.sessionStore.user
	}
};

const mapDispatchToProps = (dispatch) => {
	return {

	}
}

const TermsConditionsContainer = connect( mapStateToProps, mapDispatchToProps )(TermsConditionsComponent)



export default TermsConditionsContainer
