import request from 'superagent'

import { SESSION, LDAP } from '../constants/action-types'

export const actionUserLogout = () => {
	return {type: SESSION.USER.LOGOUT}
};

export const actionLDAPUserLogout = () => {
	return {type: LDAP.USER.LOGOUT}
};

export const actionSetPageControls = (payload) => {
	return {type: LDAP.ACTIONS.SET_PAGE_CONTROLS, payload: payload}
};

export const actionSaveHierarchyState = (payload) => {
	return function (dispatch) {
		let nodes = []
		nodes.push(payload.treeData);
		dispatch({type: LDAP.REQUESTS.GET_LDAP_HIERARCHY_SUCCESS, payload: nodes});
	}
};

export const actionResetLoginErrMsg = () => {
	return {type: SESSION.ACTIONS.RESET_LOGIN_ERRMSG}
};

// --------------- Get LDAP Sites ---------------
export function requestGetLDAPWebsites(payload) {
	return function (dispatch) {
		//alert('requestGetLDAPWebsites >> siteName: '+payload.siteName)
		dispatch(requestGetSiteUsersFetch())

		const URL = '../superman/api/ldap/edit/'+payload.siteName;
		request.get(URL)
			.set('Authorization', null)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetSiteUsersSuccess({
					siteName: payload.siteName,
					users: response.body
				}))
			).catch(
				error => dispatch(requestGetSiteUsersFailure())
			);
	}
};

// --------------- User Login DB ---------------
export function actionUserLogin(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass)
		dispatch(actionUserLoginFetch())

		request.get('../superman/api/auth/login')
			.auth(payload.name, payload.pass)
			.set('Accept', 'application/json')
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(actionUserLoginSuccess(response.body))
			).catch(
				error => dispatch(actionUserLoginFailure(error))
			);
	}
};
export const actionUserLoginFetch = () => {
	return {type: SESSION.REQUESTS.LOGIN_FETCH}
};
export const actionUserLoginSuccess = (payload) => {
	return function (dispatch) {
		dispatch({type: SESSION.REQUESTS.LOGIN_SUCCESS, payload: payload});
		dispatch(actionSetPageControls({
      addFirstLevelMode: false,
      editFirstLevelMode: false,
      addSecondLevelMode: false,
      editSecondLevelMode: false,
      addSecondLevelAttrMode: false,
    }));
	}
};
export const actionUserLoginFailure = (payload) => {
	return {type: SESSION.REQUESTS.LOGIN_FAILURE, payload: payload}
};


// --------------- User Login LDAP ---------------
export function actionUserLdapLogin(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass+' | website: '+payload.website)
		dispatch(actionUserLoginFetch())

		if(payload.website === 'VERA')
		{
				if(payload.name === 'vera' && payload.pass === 'vera')
				{
						let session = {
								"id": "Vera",
								"role": "USER",
								"user": {
										"userId": "Vera",
										"lastName": "Vera",
										"email": "vera@aot.com"
								},
								"token": {
										"hash": "B7FEF03D237569C8DB0FA1B7CAC34C7B",
										"expire": 1551848668876,
										"refreshed": 0
								}
						}
						dispatch({type: SESSION.REQUESTS.SET_TOURPLAN_CODE, payload: payload.website});
						dispatch(actionUserLoginSuccess(session))
				}
				else {
					dispatch(actionUserLoginFailure("Invalid username/password."))
				}
		}
		else
		{
				request.get('../superman/api/ldap/login')
					.auth(payload.name, payload.pass)
					.set('Accept', 'application/json')
					.timeout({
						response: 1000*10,  // Wait 10 seconds for the server to start sending response back
						deadline: 1000*60*3 // Allow 3 minute to finish loading response.
					})
					.then(
						response => {
							//alert(response.body)
							if(payload.website !== 'ADMIN')
							{
									dispatch(actionLDAPLoginSuccess(response.body,payload))
							}
							else {
									dispatch({type: SESSION.REQUESTS.SET_TOURPLAN_CODE, payload: payload.website});
									dispatch(actionUserLoginSuccess(response.body))
							}

						}
					).catch(
						error => dispatch(actionUserLoginFailure("Invalid username/password."))
					);
		}

	}
};

export const actionLDAPLoginSuccess = (loginResult,payload) => {
	return function (dispatch) {
		//alert('validating website access...'+payload.website);

		const bearer = 'Bearer ' + loginResult.token.hash;
		const URL = '../superman/api/ldap/access/'+payload.website+'/'+payload.name;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response =>
				{
					//alert(response.body)
					if(response.body !== '')
					{
							dispatch({type: SESSION.REQUESTS.SET_TOURPLAN_CODE, payload: response.body});
							dispatch(actionUserLoginSuccess(loginResult))
					}
					else {
						dispatch(actionUserLoginFailure("Invalid username/password."))
					}
				}
			).catch(
				error => dispatch(actionUserLoginFailure(error))
			);

	}
};

// --------------- Change Password ---------------
export function requestChangePassword(payload) {
	return function (dispatch) {
		dispatch(actionUserLoginFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../ninsm/api/auth/' + payload.propertyId + '/chgpwd/' + payload.currPwd + '/' + payload.newPwd;
		request.put(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestChangePasswordSuccess(response.body))
			).catch(
				error => dispatch(requestChangePasswordFailure({error, payload}))
			);
	}
};
export const requestChangePasswordSuccess = (payload) => {
	return function (dispatch) {
		dispatch({type: SESSION.REQUESTS.CHANGE_PASSWORD_SUCCESS, payload: payload});
	}
};
export const requestChangePasswordFailure = (payload) => {
	return {type: SESSION.REQUESTS.CHANGE_PASSWORD_FAILURE, payload: payload}
};

// --------------- Get LDAP Hierarchy Expanded---------------
export function requestGetLDAPHierarchyExp(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass)
		dispatch(requestGetLDAPHierarchyFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/hierarchy/exp';
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPHierarchySuccess(JSON.stringify(response.body)))
			).catch(
				error => dispatch(requestGetLDAPHierarchyFailure())
			);
	}
};
export const requestGetLDAPHierarchyFetch = () => {
	return {type: LDAP.REQUESTS.GET_LDAP_HIERARCHY_FETCH}
};
export const requestGetLDAPHierarchySuccess = (payload) => {
	return function (dispatch) {
		let nodes = []
		nodes.push(payload);
		dispatch({type: LDAP.REQUESTS.GET_LDAP_HIERARCHY_SUCCESS, payload: nodes});
	}
};

export const requestGetLDAPHierarchyFailure = () => {
	return function (dispatch) {
		dispatch(actionUserLogout());
	}
};
// --------------- Get LDAP Hierarchy Collapsed---------------
export function requestGetLDAPHierarchyCol(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass)
		dispatch(requestGetLDAPHierarchyFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/hierarchy/col';
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPHierarchySuccess(JSON.stringify(response.body)))
			).catch(
				error => dispatch(requestGetLDAPHierarchyFailure())
			);
	}
};

// --------------- Add Tree Node ---------------
export function requestAddTreeNode(payload) {
	return function (dispatch) {
		//dispatch(requestGetLDAPSitesFetch())
		const bearer = 'Bearer ' + payload.token;
		//alert('requestAddTreeNode ... treeNode: '+JSON.stringify(payload.treeNode));
		const URL = '../superman/api/ldap/hierarchy/add';
		request.put(URL)
			.set('Authorization', bearer)
			.set('Accept', 'application/json')
			.send(payload.treeNode)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => {dispatch(actionSetPageControls({
													      addFirstLevelMode: response.body.addFirstLevelMode,
													      editFirstLevelMode: response.body.editFirstLevelMode,
													      addSecondLevelMode: response.body.addSecondLevelMode,
													      editSecondLevelMode: response.body.editSecondLevelMode,
													      addSecondLevelAttrMode: response.body.addSecondLevelAttrMode,
										 					}))
											 //alert(response.body.nodeValue)
											 if(response.body.nodeKey === 'ou')
											 {
												 dispatch(requestGetOUMembers({
			          	 									token: payload.token,
			          										data: {
			            										siteName: response.body.nodeValue,
			          										}
		      							 					}));
											 }
											 else if(response.body.nodeKey === 'mail' || response.body.nodeKey === 'cn')
											 {
													 //alert('parentNodeValue: '+response.body.parentNodeValue)
													 //alert('nodeValue: '+response.body.nodeValue)
													 dispatch(requestGetOUMembers({
				          	 									token: payload.token,
				          										data: {
				            										siteName: response.body.parentNodeValue,
				          										}
			      							 					}));
													 dispatch(requestGetUserAttributes({
				          	 									token: payload.token,
				          										data: {
				            										siteName: response.body.parentNodeValue,
																				userName: response.body.nodeValue,
				          										}
			      							 					}));
													if(response.body.parentNodeValue === 'groups')
													{
														dispatch(requestGetLDAPUsers({token: payload.token}));
													}
											 }

									  }
			).catch(
				error => dispatch(actionSetPageControls({
										      addFirstLevelMode: false,
										      editFirstLevelMode: false,
										      addSecondLevelMode: false,
										      editSecondLevelMode: false,
										      addSecondLevelAttrMode: false,
										    	}))
			);
	}
};

// --------------- Get LDAP Groups ---------------
export function requestGetLDAPGroups(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass)
		dispatch(requestGetLDAPGroupsFetch());

		const URL = '../superman/api/ldap/edit/groups';
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', null)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPGroupsSuccess(response.body,payload))
			).catch(
				error => dispatch(requestGetLDAPGroupsFailure())
			);
	}
};
export const requestGetLDAPGroupsFetch = () => {
	return {type: LDAP.REQUESTS.GET_LDAP_GROUPS_FETCH}
};
export const requestGetLDAPGroupsSuccess = (payload,param) => {
	return function (dispatch) {
		dispatch({type: LDAP.REQUESTS.GET_LDAP_GROUPS_SUCCESS, payload: payload});
	}
};

export const requestGetLDAPGroupsFailure = () => {
	return function (dispatch) {
		dispatch(actionUserLogout());
	}
};

// --------------- Get LDAP Users ---------------
export function requestGetLDAPUsers(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass)
		dispatch(requestGetLDAPUsersFetch());

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/edit/users';
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPUsersSuccess(response.body,payload))
			).catch(
				error => dispatch(requestGetLDAPUsersFailure())
			);
	}
};
export const requestGetLDAPUsersFetch = () => {
	return {type: LDAP.REQUESTS.GET_LDAP_USERS_FETCH}
};
export const requestGetLDAPUsersSuccess = (payload,param) => {
	return function (dispatch) {
		dispatch({type: LDAP.REQUESTS.GET_LDAP_USERS_SUCCESS, payload: payload});
	}
};
export const requestGetLDAPUsersFailure = () => {
	return function (dispatch) {
		dispatch(actionUserLogout());
	}
};

// --------------- Get LDAP Sites ---------------
export function requestGetLDAPSites(payload) {
	return function (dispatch) {
		//alert('action> username: '+payload.name+' | password: '+payload.pass)
		dispatch(requestGetLDAPSitesFetch());

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/sites';
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPSitesSuccess(response.body,payload))
			).catch(
				error => dispatch(requestGetLDAPSitesFailure())
			);
	}
};
export const requestGetLDAPSitesFetch = () => {
	return {type: LDAP.REQUESTS.GET_LDAP_SITES_FETCH}
};
export const requestGetLDAPSitesSuccess = (payload,param) => {
	return function (dispatch) {
		dispatch({type: LDAP.REQUESTS.GET_LDAP_SITES_SUCCESS, payload: payload});
		dispatch(requestGetLDAPHierarchyCol(param));
	}
};
export const requestGetLDAPSitesFailure = () => {
	return function (dispatch) {
		dispatch(actionUserLogout());
	}
};

// --------------- Add New LDAP Site ---------------
export function requestAddNewLDAPSite(payload) {
	return function (dispatch) {
		//dispatch(requestGetLDAPSitesFetch())
		const bearer = 'Bearer ' + payload.token;
		//alert('requestAddNewLDAPSite ... bearer: '+bearer);
		const URL = '../superman/api/ldap/newsite';
		request.put(URL)
			.set('Authorization', bearer)
			.set('Accept', 'application/json')
			.send(payload.data)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPSites({token: payload.token}))
			).catch(
				error => dispatch(requestGetLDAPSites({token: payload.token}))
			);
	}
};

// --------------- Delete LDAP Site ---------------
export function requestDeleteLDAPSite(payload) {
	return function (dispatch) {
		//alert('requestDeleteLDAPSite >> siteName: '+payload.data.siteName)
		dispatch(requestGetLDAPSitesFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/delete/'+payload.data.siteName;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetLDAPSitesSuccess(response.body,payload))
			).catch(
				error => dispatch(requestGetLDAPSitesFailure())
			);
	}
};

// --------------- Get OU Members ---------------
export function requestGetOUMembers(payload) {
	return function (dispatch) {
		//alert('requestGetOUMembers >> siteName: '+payload.data.siteName)
		dispatch(requestGetSiteUsersFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/edit/'+payload.data.siteName;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetSiteUsersSuccess({
					siteName: payload.data.siteName,
					users: response.body
				}))
			).catch(
				error => dispatch(requestGetSiteUsersFailure())
			);
	}
};

export const requestGetSiteUsersFetch = () => {
	return {type: LDAP.REQUESTS.GET_SITE_USERS_FETCH}
};
export const requestGetSiteUsersSuccess = (payload) => {
	return function (dispatch) {
		dispatch({type: LDAP.REQUESTS.GET_SITE_USERS_SUCCESS, payload: payload});
	}
};
export const requestGetSiteUsersFailure = () => {
	return function (dispatch) {
		dispatch(actionUserLogout());
	}
};

// --------------- Get OU Attributes ---------------
export function requestGetOUAttributes(payload) {
	return function (dispatch) {
		//alert('requestGetOUAttributes >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName)
		dispatch(requestGetOUAttrFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/attr/'+payload.data.siteName;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetOUAttrSuccess({
					siteName: payload.data.siteName,
					attributes: response.body
				}))
			).catch(
				error => dispatch(actionUserLogout())
			);
	}
};

export const requestGetOUAttrFetch = () => {
	return {type: LDAP.REQUESTS.GET_OU_ATTR_FETCH}
};
export const requestGetOUAttrSuccess = (payload) => {
	return function (dispatch) {
		dispatch({type: LDAP.REQUESTS.GET_OU_ATTR_SUCCESS, payload: payload});
	}
};

// --------------- Edit User Attribute---------------
export function requestEditOUAttribute(payload) {
	return function (dispatch) {
		//alert('requestAddNewAttribute >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName+' | attrKey: '+payload.data.attrKey+' | attrOldValue: '+payload.data.attrOldValue+' | attrNewValue: '+payload.data.attrNewValue)
		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/edit/'+payload.data.siteName+'/'+payload.data.attrKey+'/'+payload.data.attrOldValue+'/'+payload.data.attrNewValue;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => {
					dispatch(requestGetOUAttributes(payload));
				}
			).catch(
				error => dispatch(actionUserLogout())
			);
	}
};

// --------------- Add New Second Level Entry ---------------
export function requestAddNewSecondLevel(payload) {
	return function (dispatch) {
		//alert('requestAddNewSecondLevel >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName)
		dispatch(requestGetSiteUsersFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/newuser';
		request.put(URL)
			.set('Authorization', bearer)
			.set('Accept', 'application/json')
			.send(payload.data)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetOUMembers(payload))
			).catch(
				error => dispatch(requestGetSiteUsersFailure())
			);
	}
};

// --------------- Delete Site User ---------------
export function requestDeleteSiteUser(payload) {
	return function (dispatch) {
		//alert('requestDeleteSiteUser >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName)
		dispatch(requestGetSiteUsersFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/delete/'+payload.data.siteName+'/'+payload.data.userName;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetSiteUsersSuccess({
					siteName: payload.data.siteName,
					users: response.body
				}))
			).catch(
				error => dispatch(requestGetSiteUsersFailure())
			);
	}
};

// --------------- Edit Site User---------------
export function requestGetUserAttributes(payload) {
	return function (dispatch) {
		//alert('requestEditSiteUser >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName)
		dispatch(requestGetUserAttrFetch())

		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/attr/'+payload.data.siteName+'/'+payload.data.userName;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetUserAttrSuccess({
					siteName: payload.data.siteName,
					userName: payload.data.userName,
					attributes: response.body
				}))
			).catch(
				error => dispatch(requestGetUserAttrFailure())
			);
	}
};

export const requestGetUserAttrFetch = () => {
	return {type: LDAP.REQUESTS.GET_USER_ATTR_FETCH}
};
export const requestGetUserAttrSuccess = (payload) => {
	return function (dispatch) {
		dispatch({type: LDAP.REQUESTS.GET_USER_ATTR_SUCCESS, payload: payload});
	}
};
export const requestGetUserAttrFailure = () => {
	return function (dispatch) {
		dispatch(actionUserLogout());
	}
};

// --------------- Delete User Attribute---------------
export function requestDeleteUserAttribute(payload) {
	return function (dispatch) {
		//alert('requestDeleteUserAttribute >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName+' | attrKey: '+payload.data.attrKey+' | attrValue: '+payload.data.attrValue)
		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/delete/'+payload.data.siteName+'/'+payload.data.userName+'/'+payload.data.attrKey+'/'+payload.data.attrValue;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetUserAttrSuccess({
					siteName: payload.data.siteName,
					userName: payload.data.userName,
					attributes: response.body
				}))
			).catch(
				error => dispatch(requestGetUserAttrFailure())
			);
	}
};

// --------------- Add New User Attribute---------------
export function requestAddNewAttribute(payload) {
	return function (dispatch) {
		//alert('requestAddNewAttribute >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName+' | attrKey: '+payload.data.attrKey+' | attrValue: '+payload.data.attrValue)
		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/add/'+payload.data.siteName+'/'+payload.data.userName+'/'+payload.data.attrKey+'/'+payload.data.attrValue;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetUserAttrSuccess({
					siteName: payload.data.siteName,
					userName: payload.data.userName,
					attributes: response.body
				}))
			).catch(
				error => dispatch(requestGetUserAttrFailure())
			);
	}
};

// --------------- Edit User Attribute---------------
export function requestEditUserAttribute(payload) {
	return function (dispatch) {
		//alert('requestAddNewAttribute >> siteName: '+payload.data.siteName+' | userName: '+payload.data.userName+' | attrKey: '+payload.data.attrKey+' | attrOldValue: '+payload.data.attrOldValue+' | attrNewValue: '+payload.data.attrNewValue)
		const bearer = 'Bearer ' + payload.token;
		const URL = '../superman/api/ldap/edit/'+payload.data.siteName+'/'+payload.data.userName+'/'+payload.data.attrKey+'/'+payload.data.attrOldValue+'/'+payload.data.attrNewValue;
		//alert('token >>>>> '+bearer);
		request.get(URL)
			.set('Authorization', bearer)
			.timeout({
				response: 1000*10,  // Wait 10 seconds for the server to start sending response back
				deadline: 1000*60*3 // Allow 3 minute to finish loading response.
			})
			.then(
				response => dispatch(requestGetUserAttrSuccess({
					siteName: payload.data.siteName,
					userName: payload.data.userName,
					attributes: response.body
				}))
			).catch(
				error => dispatch(requestGetUserAttrFailure())
			);
	}
};
