import React from "react";

import "./homepage.css";
import { Container } from "mdbreact";
import SevenOceansLogo from '../images/logo_login.png';

class HomePage extends React.Component {

  constructor(props) {
      super(props);
      // STEP 1: create a container <div>
      this.containerEl = document.createElement('div');
      this.externalWindow = null;
    }


  openNewWindow() {
    //alert('test');
    this.externalWindow = window.open('https://agents.vivaholidays.com.au/tips-training/login-support', '', 'fullscreen=yes');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
  }

  openPDF() {
    //alert('test');
    this.externalWindow = window.open('https://assets-us-01.kc-usercontent.com/dff71201-2385-0059-9c30-cdea80834dfd/3b0b07bf-54d8-4001-8bfb-5fd828f2b515/CruisePRO%20Introduction.pdf', '', 'fullscreen=yes');

    // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
    this.externalWindow.document.body.appendChild(this.containerEl);
  }
  render() {

    let termsLink = '/terms-of-use';

    return (
      <div className="home-component">
        <div align="center">
          <img
            src={SevenOceansLogo}
            alt=""
            height="180"
          />
        </div>

        <div>
          <div>
            <Container  className="mt-4 search-filter">

            <div className="search-label">
                <p>OUR BOOKING PLATFORM HAS MOVED</p>
                <p>Click <a href="https://pro.cruising.com.au/admin/login.aspx"><u>HERE</u></a> to be redirected to our new CruisePRO platform</p>
                <p>If you have trouble logging in, please click <a href=""  onClick={this.openNewWindow} ><u>HERE</u></a> for more assistance.</p>
                <p>Click <a href=""  onClick={this.openPDF} ><u>HERE</u></a> for more information on using CruisePRO</p>
            </div>

            <br/>
            <br/>
            <br/>
            </Container>
          </div>

        </div>

      </div>
    );
  }
}

export default HomePage;
