import React from 'react';

import { Link } from 'react-router-dom';
import { Container } from "mdbreact";
import './contact-component.css';
import HeaderContainer from '../header/header-container';

class ContactComponent extends React.Component {
  constructor(props) {
    //alert('constructor')
    super(props);
    this.handleInput = this.handleInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.state = { name: null,
                   email: null,
                   phone: null,
                   message: null,
                   errorMsg:null,
                   confirmation: false }; // <- set up react state
  }

  handleInput(event) {
    event.preventDefault();
    //alert('name: '+event.target.name+' | value: '+event.target.value)
    this.setState({
      errorMsg: null,
      messageClass: null
    });

    if(event.target.name === 'name')
    {
      this.setState({
        name: event.target.value
      });
    }
    if(event.target.name === 'email')
    {
      this.setState({
        email: event.target.value
      });
    }
    if(event.target.name === 'phone')
    {
      this.setState({
        phone: event.target.value
      });
    }
    if(event.target.name === 'message')
    {
      this.setState({message: event.target.value});
    }

  }

  handleSubmit(event) {
      event.preventDefault();
      let proceed = true;

      if(!this.state.name)
      {
        proceed = false;
        this.setState({
          errorMsg: "Name is required.",
          messageClass: "alert alert-danger"
        });
      }
      else if(!this.state.email)
      {
        proceed = false;
        this.setState({
          errorMsg: "Email is required.",
          messageClass: "alert alert-danger"
        });
      }
      else if (this.state.email && !this.validateEmail(this.state.email.trim())){
        //validate email
        proceed = false;
        this.setState({
          errorMsg: "Please enter a valid email address.",
          messageClass: "alert alert-danger"
        });
      }
      else if(!this.state.message)
      {
        proceed = false;
        this.setState({
          errorMsg: "Message is required.",
          messageClass: "alert alert-danger"
        });
      }

      if(proceed)
      {
        var phone = "";
        if(this.state.phone) phone = " (phone: "+this.state.phone+")";

        var contactRequest = {
            application: "skiddoo",
            sender: this.state.email,
            emailTo: ["contactus@skiddoo.com.au"],
            emailBcc: ["wilfredo.ahida@helloworld.com.au"],
            emailBody: this.state.message,
            subject: "Message from "+this.state.name+phone
          }

          //alert('searchRequest: '+JSON.stringify(searchRequest))
          this.props.onSendEmail(contactRequest);
          this.setState({confirmation: true});
      }

  }

  validateEmail(string) {
        var regex = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/;
        var result = string.replace(/\s/g, "").split(/,|;/);
        for(var i = 0;i < result.length;i++) {
            if(!regex.test(result[i])) {
                return false;
            }
        }
        return true;
  }

  render() {

    return (
      <div className="home-component">
        <HeaderContainer {...this.props} />
        <div>
          <div>
            <Container  className="mt-4 search-filter">

            {this.state.confirmation &&
            <div className="search-label">
              <div className="form-group col-md-12">
                <font size="4"><p>Thank you for your enquiry, we will respond as quickly as we can.</p></font>
              </div>
            </div>}

            {!this.state.confirmation &&
            <div className="search-label">

                {this.state.errorMsg &&
                <div className={this.state.messageClass} role="alert">
                  {this.state.errorMsg}
                </div>
                }

                <div className="form-group col-md-12">
                  <font size="4"><p>Please complete the details below and our team will respond to you as quickly as we can.</p></font>
                </div>
                <br/>
                <div className="form-group col-md-12">
                    <input
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Name (required)"
                      className="form-control"
                      onChange={this.handleInput}
                    />
                </div>
                <div className="form-group col-md-12">
                    <input
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Email (required)"
                      className="form-control"
                      onChange={this.handleInput}
                    />
                </div>
                <div className="form-group col-md-12">
                    <input
                      id="phone"
                      name="phone"
                      type="text"
                      placeholder="Phone"
                      className="form-control"
                      onChange={this.handleInput}
                    />
                </div>
                <div className="form-group col-md-12">
                    <textarea
                      id="message"
                      name="message"
                      rows="10"
                      className="form-control"
                      placeholder="Message (required)"
                      type="text"
                      onChange={this.handleInput}
                    />
                </div>

            </div>}


            <br/>
            <br/>
            {!this.state.confirmation &&
            <div className="search-label">
              <button className="btn btn-danger btn-md" onClick={this.handleSubmit}>Send</button>
            </div>}

            {this.state.confirmation &&
            <div className="search-label">
              <Link to="/">
              <button className="btn btn-danger btn-md">Return</button>
              </Link>
            </div>}

            </Container>
          </div>

        </div>

      </div>
    );
  }
}

export default ContactComponent;
