import { createStore, combineReducers, applyMiddleware } from "redux"
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import sessionReducer from "../reducers/session-reducer"
import { loadState, saveState, clearState } from './local-store';

const loggerMiddleware = createLogger();

const persistedState = loadState();
//const persistedState = clearState();

const store = createStore(
		combineReducers({
			'sessionStore': sessionReducer
		}),
		persistedState,
		applyMiddleware( thunkMiddleware, loggerMiddleware )
	);

store.subscribe(() => {
	saveState(store.getState());
})

export default store;
