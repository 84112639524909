import { connect } from 'react-redux';

import ContactComponent from './contact-component';

import { requestSendEmail } from '../../actions/skiddoo-actions'

const mapStateToProps = (state) => {
	return {
		user: state.sessionStore.user
	}
};

const mapDispatchToProps = (dispatch) => {
	return {
		onSendEmail: (payload) => {
					dispatch(requestSendEmail(payload));
				}
	}
}

const ContactContainer = connect( mapStateToProps, mapDispatchToProps )(ContactComponent)



export default ContactContainer
