import {SESSION} from "../constants/action-types";

import { fromJS } from 'immutable'

const initialState = {
	user: {
		isFetching: false,
		isDirty: false,
		data: {
			user: {
				username: null,
				password: null,
				firstname: null,
				lastname: null
			},
			token: {
				hash: null
			}
		},
		error: null
	}
}

const sessionReducer = (state = initialState, action) => {
	const user = fromJS(state.user).toJS();
	let payload = null;

	switch (action.type) {
		case SESSION.USER.LOGIN:
			payload = fromJS(action.payload).toJS();
			return {
				user: {
					...user,
					data: payload
				}
			};

		case SESSION.REQUESTS.LOGIN_FETCH:
			return {
				user: {
					...user,
					isFetching: true,
					isDirty: false,
					error: null
				}
			};

		case SESSION.REQUESTS.LOGIN_SUCCESS:
			payload = fromJS(action.payload).toJS();
			return {
				user: {
					...user,
					isFetching: false,
					isDirty: false,
					data: payload,
					error: null
				}
			};

		case SESSION.REQUESTS.SET_TOURPLAN_CODE:
			return {
				user: {
					...user,
					tourplan: action.payload
				}
			};

		case SESSION.REQUESTS.LOGIN_FAILURE:
			return {
				user: {
					...user,
					isFetching: false,
					isDirty: false,
					data: null,
					error: action.payload
				}
			};

		case SESSION.ACTIONS.RESET_LOGIN_ERRMSG:
			return {
				user: {
					...user,
					isFetching: false,
					isDirty: false,
					data: null,
					error: null
				}
			};

		case SESSION.USER.LOGOUT:
			return {
				user: {
					isFetching: false,
					isDirty: false,
					data: {
						user: {
							username: null,
							password: null,
							firstname: null,
							lastname: null
						},
						token: {
							hash: null
						}
					},
					error: null
				}
			};

		default:
			return state;
	}
};

export default sessionReducer;
