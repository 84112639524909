import React from 'react';

import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import './header-component.css';
import SevenOceansLogo from '../images/logo_login.png';
class HeaderComponent extends React.Component {

  redirect = () => {
    let result = (<Redirect to='/' />);
    return result;
  }

  render() {

    return (
      <div>{this.redirect()}</div>

    );
  }
}

HeaderComponent.propTypes = {
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default HeaderComponent;
